/**
 * StartPage
 */

import React from 'react';

import { Icon, Text } from 'ui-component-library/base';
import BaseLayout from 'layouts/BaseLayout';
import { StartPageModel } from 'types';
import EpiContentArea from 'components/EpiContentArea';
import { IntroBlock } from 'ui-component-library/afori';
import Link from 'components/Link';

/** StartPage component. */
const StartPage: React.FC<StartPageModel> = ({
	heading,
	preamble,
	arrowLink,
	contentArea,
}) => {
	return (
		<BaseLayout
			IntroBlock={
				(heading || preamble) && (
					<IntroBlock>
						{arrowLink ? (
							<Link to={arrowLink} className="group">
								<Text
									as="h1"
									margin={false}
									className="mb-3 group-hover:underline"
								>
									{heading}
								</Text>
								{preamble && (
									<Text as="preamble" margin={false} className="mb-0">
										{preamble}
									</Text>
								)}
								<Icon
									icon="arrow"
									size={2}
									className="flex-shrink-0 group-hover:translate-x-4 mt-6"
									aria-hidden={true}
								/>
							</Link>
						) : (
							<>
								<Text as="h1" margin={false} className="mb-3">
									{heading}
								</Text>
								{preamble && (
									<Text as="preamble" margin={false} className="mb-0">
										{preamble}
									</Text>
								)}
							</>
						)}
					</IntroBlock>
				)
			}
		>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};
export default StartPage;
