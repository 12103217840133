/**
 * EpiFragments
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	ResponsiveIframe,
	InfoBox,
	ImageModal,
	Quote,
	LinkListItem,
	LinkList,
	Grid,
	Cell,
} from 'ui-component-library/base';
import { EpiForms } from 'ui-component-library/uhmse';
import { selectEpi } from 'store/modules/epi';
import {
	FragmentModelTypes,
	RawFragmentModel,
	HeadingFragmentModel,
	ImageFragmentModel,
	VideoFragmentModel,
	InfoBoxFragmentModel,
	ReferencesBoxFragmentModel,
	TableFragmentModel,
	FormContainerFragmentModel,
	ChapterFragmentModel,
	QuoteFragmentModel,
	LinkListFragmentModel,
	InfoBoxesModel,
	EmbeddedScriptModel,
} from 'types';
import Text from 'components/Text';
import RichText from 'components/RichText';
import Image from 'components/Image';
import clsx from 'clsx';
import Link from 'components/Link';
import EmbedScript from 'components/EmbedScript';

interface Props {
	/** Epi property to use when edit the text */
	prop?: string;

	/** Fragments that should be rendered. */
	fragments?: FragmentModelTypes[];
}

const getHeadingLevel = (level: number) => {
	switch (level) {
		case 1:
			return 'h1';
		case 2:
			return 'h2';
		case 3:
			return 'h3';
		case 4:
			return 'h4';
		case 5:
			return 'h5';
		case 6:
			return 'h6';
		default:
			return 'h2';
	}
};

/** Component for rendering Episerver fragments. */
const EpiFragments: React.FC<Props> = ({ prop, fragments }) => {
	const getFragment = (fragment: FragmentModelTypes, index: number) => {
		switch (fragment.modelType) {
			case 'EmbeddedScriptModel':
				const scriptData = fragment as EmbeddedScriptModel;
				return <EmbedScript key={index} src={scriptData.url} />;
			case 'HeadingFragment':
				const data = fragment as HeadingFragmentModel;
				return (
					<Text
						as={getHeadingLevel(data.level)}
						id={data.id && data.id !== '' ? data.id : null}
						key={index}
					>
						<span dangerouslySetInnerHTML={{ __html: data.raw }} />
					</Text>
				);

			case 'Image':
				const imgData = fragment as ImageFragmentModel;

				return (
					<React.Fragment key={index}>
						{imgData.zoom ? (
							<ImageModal
								openBtnLabel={imgData.openBtnLabel}
								closeBtnLabel={imgData.openBtnLabel}
							>
								<Image
									src={imgData.src}
									alt={imgData.alt}
									prop={imgData.prop}
									figCaption={imgData.caption}
									largeSrc={imgData.largeSrc}
									size={imgData.size}
								/>
							</ImageModal>
						) : (
							<Image
								src={imgData.src}
								alt={imgData.alt}
								prop={imgData.prop}
								figCaption={imgData.caption}
								size={imgData.size}
							/>
						)}
					</React.Fragment>
				);
			case 'Video':
				const videoData = fragment as VideoFragmentModel;
				const handleClick = () => {
					if (
						typeof window !== 'undefined' &&
						window.CookieConsent &&
						window.CookieConsent.renew
					) {
						window.CookieConsent.renew();
					}
				};
				return (
					<div className="mb-4" key={index}>
						<ResponsiveIframe
							aria-label="Embedded video"
							src={videoData.embedUrl}
							data-consent-src={videoData.embedUrl}
							data-category-consent="cookie_cat_marketing"
						>
							<button
								className="consent-placeholder border border-grey p-3 m-6 absolute top-0 left-0 hover:cursor-pointer hover:bg-greyLight"
								data-category="cookie_cat_marketing"
								onClick={handleClick}
							>
								{videoData.missingConsentText}
							</button>
						</ResponsiveIframe>
					</div>
				);
			case 'InfoBox':
				const infoBoxData = fragment as InfoBoxFragmentModel;
				return (
					<div className="mt-10 mb-10" key={index}>
						<InfoBox
							heading={infoBoxData.heading}
							variant={infoBoxData.style}
							tooltipText={infoBoxData.tooltipText}
							tooltipLabel={infoBoxData.tooltipLabel}
							copyToClipboardText={infoBoxData.copyToClipboardText}
						>
							<EpiFragments fragments={infoBoxData.text?.fragments} />
						</InfoBox>
					</div>
				);
			case 'ReferencesBox':
				const referencesData = fragment as ReferencesBoxFragmentModel;
				return (
					<div className="mt-10 mb-10" key={index}>
						<InfoBox heading={referencesData.heading} variant="references">
							<EpiFragments fragments={referencesData.text?.fragments} />
						</InfoBox>
					</div>
				);
			case 'TableFragment':
				const tableData = fragment as TableFragmentModel;
				return (
					<div className="mt-10 mb-10 richtext" key={index}>
						<div
							className={clsx(
								'table-scrollable',
								tableData.stickyColumn && 'table-sticky-col'
							)}
							dangerouslySetInnerHTML={{ __html: tableData.raw }}
						/>
					</div>
				);

			case 'RawFragment':
				return (
					<RichText key={index}>
						<div
							dangerouslySetInnerHTML={{
								__html: (fragment as RawFragmentModel).raw,
							}}
						/>
					</RichText>
				);
			case 'FormContainer':
				const formData = fragment as FormContainerFragmentModel;
				return <EpiForms key={index} {...formData} />;

			case 'Chapter':
				const chapterData = fragment as ChapterFragmentModel;
				return (
					<div id={chapterData.id} key={index}>
						{chapterData.text.fragments?.map(getFragment)}
					</div>
				);
			case 'Quote':
				const quoteData = fragment as QuoteFragmentModel;
				return (
					<div className="my-12" key={index}>
						<Quote
							author={quoteData.author}
							authorInfo={quoteData.authorInformation}
						>
							{quoteData.text}
						</Quote>
					</div>
				);
			case 'LinkList':
				const linkListData = fragment as LinkListFragmentModel;
				return (
					<LinkList key={index}>
						{linkListData.items.map((item, indexLinkList) => (
							<LinkListItem
								text={item.text}
								url={item.url}
								icon={item.icon}
								LinkComponent={Link}
								key={indexLinkList}
							/>
						))}
					</LinkList>
				);

			case 'InfoBoxes':
				const infoBoxesData = fragment as InfoBoxesModel;
				return (
					<Grid
						padding={false}
						margin={false}
						className="pt-16 pb-6"
						key={index}
					>
						{infoBoxesData.boxes &&
							infoBoxesData.boxes.length > 0 &&
							infoBoxesData.boxes.map((box, index) => {
								return (
									<Cell span={12} desktop={4} key={index}>
										<div className="mb-6">
											<InfoBox heading={box.heading} variant="small">
												<EpiFragments fragments={box.text?.fragments} />
											</InfoBox>
										</div>
									</Cell>
								);
							})}
					</Grid>
				);

			default:
				return null;
		}
	};

	const epi = useSelector(selectEpi);
	const epiProps: any = {};

	if (epi?.beta?.isEditable && prop) {
		epiProps['data-epi-property-name'] = prop;
		epiProps['data-epi-property-render'] = 'none';
	}

	return <div {...epiProps}>{fragments?.map(getFragment)}</div>;
};

export default EpiFragments;
