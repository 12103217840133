import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Model, ContentTypes } from 'types';
import { RootState, AppThunk } from 'store';
import { getEpiPage, getEpiPageContent } from 'api/epiApi';
import error403 from './error403.json';

interface ModelState {
	model: Model;
	error: string | null;
	loading: boolean;
}

const initialState: ModelState = {
	error: null,
	loading: false,
	model: {
		content: undefined,
	},
};

const slice = createSlice({
	name: 'model',
	initialState,
	reducers: {
		getModelStart: (state) => {
			state.loading = true;
		},
		getModelSuccess: (state, action: PayloadAction<Model>) => {
			state.model = action.payload;
			state.error = null;
			state.loading = false;
		},
		getModelFailed: (state, action: PayloadAction<Model>) => {
			state.model = action.payload;
			state.loading = false;
		},
		updateContent: (state, action: PayloadAction<ContentTypes>) => {
			state.model.content = action.payload;
		},
	},
});

export const fetchPage =
	(
		routeApiUrl: string,
		route: string,
		querystring: string,
		hashstring: string
	): AppThunk =>
	async (dispatch) => {
		let data;
		dispatch(getModelStart());

		try {
			data = await getEpiPage(routeApiUrl, route, querystring, hashstring);
		} catch (err) {
			if ((err as any).response.status === 403) {
				dispatch(getModelFailed(error403 as any));
			}
			return;
		}

		dispatch(getModelSuccess(data));
	};

export const fetchPageContent =
	(apiUrl: string, contentLink: string): AppThunk =>
	async (dispatch) => {
		let data;
		console.log('FETCHPAGECONTENT');

		try {
			data = await getEpiPageContent(apiUrl, contentLink);
		} catch (err) {
			console.log(err);
			return;
		}
		console.log('UPDATE_CONTENT');

		dispatch(updateContent(data));
	};

export const selectModel = (state: RootState) => state.model;
export const selectContent = (state: RootState) => state.model.model.content;
export const selectBreadcrumbs = (state: RootState) =>
	state.model.model.breadcrumbs?.breadcrumbs;
export const selectQuicklinks = (state: RootState) =>
	state.model.model.quickLinks?.quickLinks;
export const selectMetaData = (state: RootState) => state.model.model.metadata;
export const selectAlertBar = (state: RootState) => state.model.model.alertBar;
export const selectBetaBar = (state: RootState) => state.model.model.betaBar;
export const selectFooterContact = (state: RootState) =>
	state.model.model.contact;
export const selectFooterQuickLinks = (state: RootState) =>
	state.model.model.quickLinksFooter;
export const selectFooterPopularPages = (state: RootState) =>
	state.model.model.popularPages;
export const selectFooterCopyrightFooter = (state: RootState) =>
	state.model.model.copyrightFooter;
export const selectCookieBar = (state: RootState) =>
	state.model.model.cookieBar;
export const selectSkipToMainContent = (state: RootState) =>
	state.model.model.skipToMainContent;
export const selectLanguageSelector = (state: RootState) =>
	state.model.model.languages;
export const selectLogotype = (state: RootState) => state.model.model.logotype;
export const { getModelStart, getModelSuccess, getModelFailed, updateContent } =
	slice.actions;

export default slice.reducer;
