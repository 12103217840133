import React, { useEffect, createContext } from 'react';
import './tailwind.output.css';
import './fonts.css';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RouteHandler } from './routeHandler';
import { useEpiEvents, useTabFocus } from 'hooks';
import { selectContent } from 'store/modules/model';
import { ContentTypes } from 'types';
import objectFitImages from 'object-fit-images';
import ClipboardJS from 'clipboard';

interface PropsInterface {
	Router: any;
}

export const routePatterns = ['/:siteRoute*'];

// Global xstate context.
export const GlobalStateContext = createContext<any>({});

const getLocation = (pageContent: ContentTypes | undefined) => {
	let location = '';

	if (pageContent?.url) {
		location = pageContent.url;
	} else if (typeof window !== 'undefined') {
		location = window.location.pathname;
	}

	return location;
};

const App: React.FC<PropsInterface> = ({ Router }) => {
	const pageContent = useSelector(selectContent);
	const location = getLocation(pageContent);

	useEpiEvents();
	useTabFocus();

	useEffect(() => {
		new ClipboardJS('.clipboard');

		// Polyfill - Object-fit-images
		objectFitImages(null);
	}, []);

	return (
		<Router location={location} context={{}}>
			<Switch>
				{routePatterns.map((routePattern) => (
					<Route key={routePattern} path={routePattern}>
						<RouteHandler />
					</Route>
				))}
			</Switch>
		</Router>
	);
};

export default App;
