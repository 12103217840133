/**
 * CalendarEventListingPage
 */

import React, { useState } from 'react';
import {
	Grid,
	Cell,
	CalendarEventList,
	CalendarEventListItem,
	FilterButton,
	FilterButtonGroup,
} from 'ui-component-library/base';
import { CalendarEventListingPageModel, CalendarEventModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import Link from 'components/Link';

/** CalendarEventListingPage component. */
const CalendarEventListingPage: React.FC<CalendarEventListingPageModel> = ({
	heading,
	preamble,
	showMoreButtonText,
	numberOfHitsPerPage,
	apiUrl,
	calendarEvents,
	contentArea,
}) => {
	const [activeTab, setActiveTab] = useState(
		(calendarEvents.futureEvents && 'tab-1') ||
			(calendarEvents.pastEvents && 'tab-2')
	);

	const [eventList, setEventList] = useState(
		calendarEvents.futureEvents || calendarEvents.pastEvents
	);

	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
				</Cell>
			</Grid>
			<Grid padding={false} margin={false} className="lg:px-6 px-4 mb-20">
				<Cell span={12} desktop={7}>
					<FilterButtonGroup>
						{calendarEvents.futureEvents && (
							<FilterButton
								isActive={activeTab === 'tab-1'}
								text={'Kommande'}
								onClick={(e: any) => {
									setActiveTab('tab-1');
									setEventList(calendarEvents.futureEvents);
								}}
							/>
						)}
						{calendarEvents.pastEvents && (
							<FilterButton
								isActive={activeTab === 'tab-2'}
								text={'Passerade'}
								onClick={(e: any) => {
									setActiveTab('tab-2');
									setEventList(calendarEvents.pastEvents);
								}}
							/>
						)}
					</FilterButtonGroup>
					{eventList && (
						<CalendarEventList>
							{eventList.map((event: CalendarEventModel, index: number) => {
								return (
									<CalendarEventListItem
										startDay={event.start.day}
										startMonth={event.start.monthNameShort}
										endDay={event.end.day}
										endMonth={event.end.monthNameShort}
										heading={event.heading}
										category={event.category}
										text={event.text}
										url={event.url}
										hasEndDate={calendarEvents.hasMultiDayEvents}
										LinkComponent={Link}
										key={index}
									/>
								);
							})}
						</CalendarEventList>
					)}
				</Cell>
				{contentArea && contentArea.length > 0 && (
					<Cell span={12} desktop={4} className="lg:ml-1/12 mt-8">
						<EpiContentArea content={contentArea} />
					</Cell>
				)}
			</Grid>
		</BaseLayout>
	);
};

export default CalendarEventListingPage;
