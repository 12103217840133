/**
 * NewsListingPage
 */

import React from 'react';
import { Grid, Cell, NewsList, NewsListItem } from 'ui-component-library/base';
import { NewsListingPageModel, NewsModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiContentArea from 'components/EpiContentArea';
import Link from 'components/Link';

/** NewsListingPage component. */
const NewsListingPage: React.FC<NewsListingPageModel> = ({
	heading,
	preamble,
	listingModel,
	contentArea,
}) => {
	return (
		<BaseLayout>
			<Grid
				padding={false}
				margin={false}
				className="lg:px-6 px-4 -mt-4 md:mt-0"
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
				</Cell>
			</Grid>
			<Grid padding={false} margin={false} className="lg:px-6 px-4 mb-20">
				<Cell span={12} desktop={7} className="mb-10">
					<NewsList>
						{listingModel.items.map((event: NewsModel, index: number) => {
							return (
								<NewsListItem
									date={event.published}
									heading={event.heading}
									text={event.text}
									url={event.url}
									LinkComponent={Link}
									key={index}
								/>
							);
						})}
					</NewsList>
				</Cell>
				{contentArea && contentArea.length > 0 && (
					<Cell span={12} desktop={4} className="lg:ml-1/12">
						<EpiContentArea content={contentArea} />
					</Cell>
				)}
			</Grid>
		</BaseLayout>
	);
};

export default NewsListingPage;
