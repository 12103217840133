/**
 * Meta
 */

import React from 'react';
import MetaWriter from 'ui-component-library/base/Meta';
import { useSelector } from 'react-redux';
import { selectLanguageSelector, selectMetaData } from 'store/modules/model';
import favicon144 from './ico/apple-touch-icon-144x144-precomposed.png';
import favicon114 from './ico/apple-touch-icon-114x114-precomposed.png';
import favicon72 from './ico/apple-touch-icon-72x72-precomposed.png';
import favicon57 from './ico/apple-touch-icon-57x57-precomposed.png';
import favicon from './ico/favicon.png';

/** Component that handles meta-data for the <head>. */
const Meta: React.FC = () => {
	const metaData = useSelector(selectMetaData);
	const mappedMetaData = {
		title: metaData?.title || undefined,
		description: metaData?.description || undefined,
		ogSiteName: metaData?.openGraph?.siteName || undefined,
		ogType: metaData?.openGraph?.type || 'website',
		ogTitle: metaData?.openGraph?.title || undefined,
		ogUrl: metaData?.openGraph?.url || undefined,
		ogDescription: metaData?.openGraph?.description || undefined,
		ogImage: metaData?.openGraph?.image || undefined,
		twitterCard: metaData?.twitter?.card || 'summary',
		twitterTitle: metaData?.twitter?.title || undefined,
		twitterDescription: metaData?.twitter?.description || undefined,
		twitterImage: metaData?.twitter?.image || undefined,
	};

	const languageSelector = useSelector(selectLanguageSelector);

	return (
		<MetaWriter
			htmlAttributes={{ lang: languageSelector?.culture }}
			{...mappedMetaData}
		>
			<meta http-equiv="X-UA-Compatible" content="IE=Edge" />
			<link rel="stylesheet" href="https://use.typekit.net/oyr5sbc.css" />

			{metaData?.languages?.map((item, i) => {
				return (
					<link
						rel="alternate"
						hrefLang={item.culture}
						href={item.url}
						key={i}
					/>
				);
			})}
			<meta name="viewport" content="width=device-width, initial-scale=1" />

			<link
				rel="apple-touch-icon-precomposed"
				sizes="144x144"
				href={favicon144}
			/>
			<link
				rel="apple-touch-icon-precomposed"
				sizes="114x114"
				href={favicon114}
			/>
			<link rel="apple-touch-icon-precomposed" sizes="72x72" href={favicon72} />
			<link rel="apple-touch-icon-precomposed" href={favicon57} />
			<link rel="shortcut icon" href={favicon} />
			{metaData?.canonicalUrl && (
				<link rel="canonical" href={metaData?.canonicalUrl} />
			)}
		</MetaWriter>
	);
};

export default Meta;
