import { iconTypes } from 'ui-component-library/base/Icon/Icon';

declare global {
	interface Window {
		__model: any;
		__culture: string;
		epi: any;
		dataLayer: any;
		$kundo_chat: any;
		CookieConsent: any;
		CookieInformation: any;
	}
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			window: any;
			document: any;
			Styled: any;
			React: any;
			ReactDOM: any;
			ReactDOMServer: any;
		}
	}
}

export interface EpiEvent {
	beta?: {
		isEditable: boolean;
		inEditMode: boolean;
		ready: boolean;
	};
}

export type ContentTypes =
	| StartPageModel
	| InspirationPageModel
	| ArticlePageModel
	| NewsListingPageModel
	| CalendarEventPageModel
	| SectionPageModel
	| CalendarEventListingPageModel;

export interface Address {
	name: string;
	street: string;
	zipCode: string;
	city: string;
}

export interface ContactChannel {
	name: string;
	value: string;
	type: string;
}

export interface NavigationItemModel {
	title: string;
	url: string;
	isOpen?: boolean;
	isActive?: boolean;
	items?: NavigationItemModel[];
}

export interface LogotypeModel {
	url?: string;
	alt?: string;
	logo?: string;
}
export interface Model {
	head?: ModelHead;
	languageTags?: Array<{
		culture: string;
		url: string;
	}>;
	metadata?: ModelMetadata;
	content?: ContentTypes;
	logotype?: LogotypeModel;
	navigation?: {
		navigationButtonLabel: string;
		accessibilityDescription: string;
		openNavigationPaneLabel: string;
		closeNavigationPaneLabel: string;
		openNavigationItemLabel: string;
		closeNavigationItemLabel: string;
		items: NavigationItemModel[];
	};
	search?: ModelSearch;
	breadcrumbs?: {
		breadcrumbs: ModelBreadcrumbs[];
	};
	quickLinks?: {
		quickLinks: QuickLink[];
	};
	alertBar?: {
		alerts: Array<{
			id: string;
			text: {
				modelType: 'Html';
				fragments: RawFragmentModel[];
			};
			closeButtonLabel: string;
			isCloseable: boolean;
		}>;
	};
	betaBar?: {
		text: {
			modelType: 'Html';
			fragments: RawFragmentModel[];
		};
	};
	quickLinksBar?: {
		label: string;
		links: Array<{
			url: string;
			text: string;
			target?: string;
			icon?: iconTypes;
			modelType?: string;
		}>;
	};
	cookieBar?: {
		heading: string;
		text: {
			modelType: 'Html';
			fragments: RawFragmentModel[];
		};
		buttonText: string;
	};
	skipToMainContent?: {
		text: string;
	};
	contact?: {
		addresses: {
			first?: Address;
			second?: Address;
		};
		channels: {
			heading: string;
			items: ContactChannel[];
		};
		social: ContactChannel[];
	};
	popularPages?: PopularPagesModel;
	quickLinksFooter?: {
		quickLinks: QuickLink[];
	};
	copyrightFooter?: string;
	theme?: {
		color?: string;
	};
	languages?: {
		culture: string;
		languages: Array<{
			id: string;
			text: string;
			name: string;
			url: string;
			isActive: boolean;
		}>;
	};
}

export interface ModelSearch {
	apiUrl: string;
	searchPageUrl: string;
	placeholderText: string;
	performSearchText: string;
	showMoreResultsText: string;
	numberOfHitsPerPage: number;
}

export interface QuickLink {
	href: string;
	title: string;
	text: string;
	target: string;
}

export interface ModelBreadcrumbs {
	url: string;
	text: string;
	isActive: boolean;
}

interface ModelMetadata {
	languageTags: string | null;
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	canonicalUrl?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
	languages?: LangaugeTag[];
}

interface LangaugeTag {
	culture: string;
	url: string;
}

interface ModelHead {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	openGraphTitle?: string | null;
	openGraphImageUrl?: string | null;
	openGraphDescription?: string | null;
	twitterTitle?: string | null;
	twitterDescription?: string | null;
	twitterImageUrl?: string | null;
}

export interface ModelReact {
	containerId: string;
	clientOnly: boolean;
	apiUrl: string;
	apiEditUrl: string;
	renderingState: RenderingStates;
}

export enum RenderingStates {
	ServerSide = 1,
	Hydrate,
	ClientSide,
}

export interface StartPageModel {
	modelType: 'AforiStartPage';
	id: string;
	heading: string;
	preamble?: string;
	arrowLink?: string;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface ArticlePageModel {
	modelType: 'AforiArticlePage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface InspirationPageModel {
	modelType: 'AforiInspirationPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface CalendarEventPageModel {
	modelType: 'AforiCalendarEventPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	calendarEventDetails?: calendarEventDetailsItemModel[];
	contentArea?: ContentAreaModelTypes[];
}

export interface CalendarEventListingPageModel {
	modelType: 'AforiCalendarEventListingPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	showMoreButtonText: string;
	apiUrl: string;
	numberOfHitsPerPage: number;
	calendarEvents: CalendarEventsModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface NewsListingPageModel {
	modelType: 'AforiNewsListingPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	apiUrl: string;
	listingModel: NewsListingModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface SectionPageModel {
	modelType: 'AforiSectionPage';
	id: string;
	heading: string;
	preamble: string;
	apiUrl: string;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface CalendarEventsModel {
	hasMultiDayEvents: boolean;
	futureEvents?: CalendarEventModel[];
	pastEvents?: CalendarEventModel[];
}
export interface FilterButtonGroupAggregationsModel {
	name: string;
	type: string;
	path: string;
	buckets: FilterButtonGroupAggregationsBucketModel[];
}

export interface FilterButtonGroupAggregationsBucketModel {
	name: string;
	count?: number;
	term?: string;
	disabled?: boolean;
	selected?: boolean;
}

export interface CalendarEventsListingModel {
	offset: number;
	fetch: number;
	size: number;
	total: number;
	sortOrder: number;
	hasMultiDayEvents: boolean;
	hasMore: boolean;
	calendarEvents: CalendarEventModel[];
	aggregations: FilterButtonGroupAggregationsModel[];
}

export interface NewsListingModel {
	offset: number;
	fetch: number;
	size: number;
	total: number;
	sortOrder: number;
	items: NewsModel[];
}

export interface TabModel {
	tabName: string;
	id: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface ListingModel {
	page: number;
	size: number;
	total: number;
	calendarEvents?: CalendarEventModel[];
	news?: NewsModel[];
}

export interface NewsModel {
	published: string;
	heading: string;
	text: string;
	url: string;
}

export interface EditorModel {
	name: string;
	title: string;
	image: string;
}

export interface CalendarEventModel {
	start: { day: string; monthNameShort: string };
	end: { day: string; monthNameShort: string };
	heading: string;
	category: string;
	text?: string;
	url: string;
}
export interface TextHtmlModel {
	modelType: 'Html';
	fragments: FragmentModelTypes[];
}

export interface LinkListModel {
	heading: string;
	tooltipLabel: string;
	tooltipText: string;
	width: 50 | 100;
	items: Array<{ text: string; url: string }>;
}

export interface AggregationsModel {
	name: string;
	type: string;
	path: string;
	selectedBuckets: number;
	buckets: AggregationsBucketModel[];
}

export interface AggregationsBucketModel {
	count: number;
	term: string;
	selected?: boolean;
	disabled?: boolean;
}

export interface FilterButtonGroupAggregationsBucketModel {
	name: string;
	count?: number;
	term?: string;
	disabled?: boolean;
	selected?: boolean;
}

export interface TocModel {
	heading: string;
	titles: TocItemModel[];
	chapters: Array<string>;
	isSticky?: boolean;
}

export interface TocItemModel {
	text: string;
	targetId: string;
	titles?: TocItemModel[];
}

export interface ArticleHeaderModel {
	published?: string;
	categories?: ArticleHeaderCategoriesModel[];
	social?: boolean;
}

export interface ArticleHeaderCategoriesModel {
	url?: string;
	text: string;
}

export interface ImageHeaderModel {
	src?: string;
	altText?: string;
	text?: string;
}

export interface calendarEventDetailsItemModel {
	heading: string;
	text: string;
	type: string;
	url?: string;
}

export interface PhaseModel {
	name: string;
	url: string;
	next?: PhaseModel | null;
	previous?: PhaseModel | null;
	i18n: {
		next: string;
		previous: string;
	};
}

export interface ProductModel {
	name: string;
	code: string;
	price: {
		amount: number;
		currency: string;
	};
	image: string;
	url: string;
}

interface stdImage {
	src: string;
	alt: string;
	sizes: {
		w300: string;
		w600: string;
		w800: string;
	};
}

export interface PuffModel {
	modelType: 'Puff';
	heading: string;
	url: string;
	text: string;
}

export interface AssessmentModel {
	riskAreaName?: string;
	targetId?: string;
	step: number;
	stepName: string;
	assessment: string;
	assessmentValue: string;
}

export type FragmentTypes =
	| 'HeadingFragment'
	| 'RawFragment'
	| 'ListFragment'
	| 'Image'
	| 'Video'
	| 'InfoBox'
	| 'TableFragment'
	| 'ReferencesBox'
	| 'FormContainer'
	| 'Chapter'
	| 'Quote'
	| 'LinkList'
	| 'InfoBoxes'
	| 'EmbeddedScriptModel';

export interface FragmentModel {
	modelType: FragmentTypes;
}

export interface HeadingFragmentModel extends FragmentModel {
	level: number;
	id?: string;
	raw: string;
}

export interface RawFragmentModel extends FragmentModel {
	raw: string;
}

export interface ListFragmentModel extends FragmentModel {
	raw: string;
}

export interface ImageFragmentModel extends FragmentModel {
	src: string;
	largeSrc?: string;
	alt: string;
	prop?: string;
	caption?: string;
	zoom?: boolean;
	openBtnLabel?: string;
	closeBtnLabel?: string;
	size?: 'default' | 'medium' | 'large';
}

export interface VideoFragmentModel extends FragmentModel {
	embedUrl: string;
	missingConsentText: string;
}

export interface InfoBoxFragmentModel extends FragmentModel {
	heading: string;
	tooltipText?: string;
	tooltipLabel?: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
	style: 'warning' | 'default';
	copyToClipboardText?: string;
}

export interface ReferencesBoxFragmentModel extends FragmentModel {
	heading: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface TableFragmentModel extends FragmentModel {
	caption?: string;
	raw: string;
	stickyColumn?: boolean;
}

export interface ChapterFragmentModel extends FragmentModel {
	id: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface QuoteFragmentModel extends FragmentModel {
	text?: string;
	author?: string;
	authorInformation?: string;
}

export interface InfoBoxesModel extends FragmentModel {
	boxes: Array<{
		heading: string;
		text: {
			modelType: 'Html';
			fragments: RawFragmentModel[];
		};
	}>;
}

export interface EmbeddedScriptModel extends FragmentModel {
	url: string;
}

export interface LinkListFragmentModel extends FragmentModel {
	items: LinkListLinkModel[];
}

export interface LinkListLinkModel {
	url: string;
	text: string;
	icon?: iconTypes;
}

export interface FormContainerFragmentModel extends FragmentModel {
	id: string;
	currentStepIndex: number;
	method?: string;
	action?: string;
	encodingType?: string;
	title?: string | null;
	description?: string | null;
	redirectUrl?: string;
	steps: any[];
	fields: any;
	confirmationMessage: string | null;
	dependencies?: any[];
}

export type FragmentModelTypes =
	| HeadingFragmentModel
	| RawFragmentModel
	| ListFragmentModel
	| ImageFragmentModel
	| VideoFragmentModel
	| InfoBoxFragmentModel
	| ReferencesBoxFragmentModel
	| TableFragmentModel
	| FormContainerFragmentModel
	| ChapterFragmentModel
	| LinkListFragmentModel
	| InfoBoxesModel
	| EmbeddedScriptModel;

export interface CalendarEventPuffWithImageModel extends BlockModel {
	image?: string;
	alt?: string;
	heading: string;
	url: string;
	text?: string;
	eventDate?: string;
	eventMonth?: string;
	category?: string;
}

export interface PuffWithImageModel extends BlockModel {
	image?: string;
	alt?: string;
	heading: string;
	url: string;
	text?: string;
	published: string;
	categories?: Array<{ url: string; text: string }>;
}

export interface CalendarEventListBlockModel extends BlockModel {
	heading: string;
	link: string;
	linkText: string;
	hasMultiDayEvents: boolean;
	items: CalendarEventModel[];
}

export interface NewsListBlockModel extends BlockModel {
	heading: string;
	link: string;
	linkText?: string;
	items?: NewsModel[];
}

export interface SectionBlockModel extends BlockModel {
	background: boolean;
	items?: ContentAreaModelTypes[];
}

export interface SectionColumnBlockModel extends BlockModel {
	heading?: string;
	text?: string;
	items?: ContentAreaModelTypes[];
}

export interface TextBlockModel extends BlockModel {
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export type ContentAreaModelTypes =
	| SectionBlockModel
	| SectionColumnBlockModel
	| PuffModel
	| CalendarEventPuffWithImageModel
	| PuffWithImageModel
	| NewsListBlockModel
	| CalendarEventListBlockModel
	| TextBlockModel;

export type BlockTypes =
	| 'SectionBlock'
	| 'SectionColumnSmall'
	| 'SectionColumnMedium'
	| 'SectionColumnLarge'
	| 'Puff'
	| 'PuffWithImage'
	| 'PuffWithNoImage'
	| 'CalendarEventPuffWithImage'
	| 'CalendarEventPuffWithNoImage'
	| 'CalendarEventListBlock'
	| 'NewsListBlock'
	| 'TextBlock';

export interface BlockModel {
	modelType: BlockTypes;
}

export interface PopularPagesModel {
	first: PopularPagesLinksModel;
	second: PopularPagesLinksModel;
}

export interface PopularPagesLinksModel {
	heading: string;
	links: PopularPageLink[];
}

export interface PopularPageLink {
	href: string;
	text: string;
	title: string;
	target: string;
}
