/**
 * EpiContentArea
 */

import clsx from 'clsx';
import React from 'react';
import {
	CalendarEventListBlockModel,
	CalendarEventPuffWithImageModel,
	NewsModel,
	PuffWithImageModel,
	NewsListBlockModel,
	SectionBlockModel,
	ContentAreaModelTypes,
	PuffModel,
	TextBlockModel,
	SectionColumnBlockModel,
} from 'types';
import {
	Button,
	Cell,
	Grid,
	Icon,
	Text,
	CalendarEventList,
	CalendarEventListItem,
	CalendarEventPuff,
	NewsList,
	NewsListItem,
	NewsPuff,
	NewsPuffCategory,
} from 'ui-component-library/base';
import Link from 'components/Link';
import { NavigationPuff } from 'ui-component-library/uhmse';
import EpiFragments from 'components/EpiFragments';
import {
	getBlockColumnDesktop,
	getBlockColumnTablet,
	getPuffMarginBottom,
	getSectionColumnDesktop,
	getSectionColumnTablet,
} from './helpers';
import { useIsMobile, useIsTablet } from 'hooks/useMediaQuery';

interface Props {
	/** Epi property containing content for the content area */
	content?: any;

	/** Epi properties for on page editing */
	[htmlAttributes: string]: any;
}

/** Epi content area for handling blocks */
const EpiContentArea: React.FC<Props> = ({
	content = [],
	...htmlAttributes
}) => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const renderContentBlock = (
		block: ContentAreaModelTypes,
		headingLevel: 'h2' | 'h3',
		index: number
	) => {
		switch (block.modelType) {
			case 'SectionBlock':
				const layoutData = block as SectionBlockModel;
				return (
					<div
						className={clsx(
							layoutData.background && 'bg-yellowLight',
							'pt-16 pb-20'
						)}
						key={index}
					>
						<Grid padding={false} className="pl-4 lg:pl-6 -mb-10 md:-mb-16">
							{layoutData.items &&
								layoutData.items.map((item: any, indexLayoutData: number) => {
									const sectionHeadingLevel = 'h2';
									return (
										<React.Fragment key={indexLayoutData}>
											{renderContentBlock(
												item,
												sectionHeadingLevel,
												indexLayoutData
											)}
										</React.Fragment>
									);
								})}
						</Grid>
					</div>
				);

			case 'SectionColumnSmall':
			case 'SectionColumnMedium':
			case 'SectionColumnLarge':
				const sectionColumnData = block as SectionColumnBlockModel;
				return (
					<Cell
						span={12}
						tablet={getSectionColumnTablet(block.modelType)}
						desktop={getSectionColumnDesktop(block.modelType)}
					>
						<div className="mb-8">
							{sectionColumnData.heading && (
								<Text as="h2" margin={false} className="mb-5">
									{sectionColumnData.heading}
								</Text>
							)}
							{sectionColumnData.text && (
								<Text as="p" margin={false} className="text-black mb-4">
									{sectionColumnData.text}
								</Text>
							)}
						</div>
						<Grid
							padding={false}
							margin={block.modelType === 'SectionColumnSmall' ? false : true}
						>
							{sectionColumnData?.items &&
								sectionColumnData?.items.length > 0 &&
								sectionColumnData?.items?.map((item, index) => {
								const blockHeadingLevel = sectionColumnData.heading
									? 'h3'
									: 'h2';
								return (
									<Cell
										span={12}
										tablet={getBlockColumnTablet(
											block.modelType,
											item.modelType
										)}
										desktop={getBlockColumnDesktop(
											block.modelType,
											item.modelType
										)}
										className={clsx(
											item.modelType === 'Puff' &&
												`puff--border-top puff--border-bottom
												${getPuffMarginBottom(
													block.modelType,
													isMobile,
													isTablet,
													sectionColumnData.items?.length as number,
													index
												)}`
										)}
										key={index}
									>
										{renderContentBlock(item, blockHeadingLevel, index)}
									</Cell>
								);
							})}
						</Grid>
					</Cell>
				);
			case 'Puff':
				const puffData = block as PuffModel;
				return (
					<NavigationPuff
						url={puffData.url}
						heading={puffData.heading}
						text={puffData.text}
						headingLevel={headingLevel}
						LinkComponent={Link}
						key={index}
					/>
				);
			case 'PuffWithNoImage':
			case 'PuffWithImage':
				const newsPuffData = block as PuffWithImageModel;
				return (
					<NewsPuff
						src={newsPuffData.image}
						alt={newsPuffData.alt}
						heading={newsPuffData.heading}
						text={newsPuffData.text}
						published={newsPuffData.published}
						url={newsPuffData.url}
						LinkComponent={Link}
						className="mb-10 md:mb-16"
						headingLevel={headingLevel}
						key={index}
					>
						{newsPuffData.categories &&
							newsPuffData.categories.length > 0 &&
							newsPuffData.categories.map((category, index) => (
								<NewsPuffCategory
									key={`puff-${index}`}
									text={category.text}
									url={category.url}
									LinkComponent={Link}
								/>
							))}
					</NewsPuff>
				);
			case 'CalendarEventPuffWithNoImage':
			case 'CalendarEventPuffWithImage':
				const eventPuffData = block as CalendarEventPuffWithImageModel;
				return (
					<CalendarEventPuff
						src={eventPuffData.image}
						alt={eventPuffData.alt}
						heading={eventPuffData.heading}
						text={eventPuffData.text}
						eventDate={eventPuffData.eventDate}
						eventMonth={eventPuffData.eventMonth}
						url={eventPuffData.url}
						category={eventPuffData.category}
						LinkComponent={Link}
						headingLevel={headingLevel}
						className="mb-10 md:mb-16"
						key={index}
					/>
				);

			case 'CalendarEventListBlock':
				const eventListData = block as CalendarEventListBlockModel;
				return (
					<React.Fragment key={index}>
						<CalendarEventList borderTop={true}>
							{eventListData.items.map((event, index) => {
								return (
									<CalendarEventListItem
										startDay={event.start.day}
										startMonth={event.start.monthNameShort}
										endDay={event.end.day}
										endMonth={event.end.monthNameShort}
										heading={event.heading}
										category={event.category}
										url={event.url}
										hasEndDate={eventListData.hasMultiDayEvents}
										LinkComponent={Link}
										headingLevel={headingLevel}
										key={index}
									/>
								);
							})}
						</CalendarEventList>
						<div className="flex justify-around my-8">
							<Button
								variant="secondary"
								href={eventListData.link}
								LinkComponent={Link}
								as="a"
							>
								{eventListData.linkText}
								<Icon
									icon="chevron"
									direction="left"
									size={0.75}
									className="ml-2"
									aria-hidden={true}
								/>
							</Button>
						</div>
					</React.Fragment>
				);
			case 'NewsListBlock':
				const newsListData = block as NewsListBlockModel;
				return (
					<React.Fragment key={index}>
						<NewsList>
							{newsListData.items &&
								newsListData.items.map((news: NewsModel, index: number) => {
									return (
										<NewsListItem
											date={news.published}
											heading={news.heading}
											url={news.url}
											LinkComponent={Link}
											headingLevel={headingLevel}
											key={index}
										/>
									);
								})}
						</NewsList>
						<div className="flex justify-around my-8">
							<Button
								variant="secondary"
								href={newsListData.link}
								LinkComponent={Link}
								as="a"
							>
								{newsListData.linkText}
								<Icon
									icon="chevron"
									direction="left"
									size={0.75}
									className="ml-2"
									aria-hidden={true}
								/>
							</Button>
						</div>
					</React.Fragment>
				);
			case 'TextBlock':
				const textData = block as TextBlockModel;
				return (
					<div className="py-8" key={index}>
						<Grid padding={false} className="pl-4 lg:pl-6">
							<Cell>
								<EpiFragments prop="text" fragments={textData.text.fragments} />
							</Cell>
						</Grid>
					</div>
				);

			default:
				return null;
		}
	};
	// const epi = useSelector(selectEpi);
	// const epiProps: any = {};

	// if (epi?.beta?.isEditable && prop) {
	// 	epiProps['data-epi-property-name'] = prop;
	// 	epiProps['data-epi-property-render'] = 'none';
	// }

	return (
		<div {...htmlAttributes}>
			{content.map((block: ContentAreaModelTypes, index: number) =>
				renderContentBlock(block, 'h2', index)
			)}
		</div>
	);
};

export default EpiContentArea;
