import AforiStartPage from './StartPage';
import AforiCalendarEventPage from './CalendarEventPage';
import AforiNewsListingPage from './NewsListingPage';
import AforiCalendarEventListingPage from './CalendarEventListingPage';
import AforiInspirationPage from './InspirationPage';
import AforiSectionPage from './SectionPage';
import AforiArticlePage from './ArticlePage';
import ErrorPage404 from './ErrorPage404';

import { ContentTypes } from 'types';

const Pages: any = {
	AforiStartPage,
	AforiCalendarEventPage,
	AforiNewsListingPage,
	AforiCalendarEventListingPage,
	AforiInspirationPage,
	AforiSectionPage,
	AforiArticlePage,
	ErrorPage404,
};

export const PagesMapper = (pageContent: ContentTypes | undefined) => {
	if (pageContent) {
		let page: any = Pages[pageContent.modelType];

		if (page) {
			return page;
		}
	}

	return ErrorPage404;
};
