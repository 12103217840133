/**
 * RouteHandler
 */

import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { PagesMapper } from 'pages';
import ErrorPage404 from 'pages/ErrorPage404';
import { Loader } from 'ui-component-library/base';
import {
	selectModel,
	selectContent,
	selectMetaData,
	fetchPage,
	selectLanguageSelector,
} from 'store/modules/model';
import { updateRenderingState, selectReact } from 'store/modules/react';
import { RenderingStates } from './types';
import { useFirstRender } from 'ui-component-library/hooks';

/** Loads data and renders the correct page based on the route. */
const RouteHandler: React.FC = () => {
	let { siteRoute } = useParams();
	const history = useHistory();
	let querystring = useLocation().search;
	let hashstring = useLocation().hash;
	siteRoute = siteRoute || '/';
	const ariaLiveRef = useRef<HTMLDivElement>(null);
	const emptyFocusDivRef = useRef<HTMLDivElement>(null);
	const pageRef = useRef<any>(null);
	const { action } = useHistory();
	const dispatch = useDispatch();
	const { error, loading } = useSelector(selectModel);
	const pageContent = useSelector(selectContent);
	const metaData = useSelector(selectMetaData);
	const languages = useSelector(selectLanguageSelector);
	const { renderingState, apiUrl } = useSelector(selectReact);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const Page = PagesMapper(pageContent);
	const isFirstRender = useFirstRender();

	// Fix siteRoute so we don't request '//'.
	if (
		apiUrl &&
		siteRoute &&
		apiUrl.charAt(apiUrl.length - 1) === '/' &&
		siteRoute.charAt(0) === '/'
	) {
		siteRoute = siteRoute.substr(1);
	}

	// Load the page
	useEffect(() => {
		// First hydrate rendering we wont be doing any request, becase we already have the data from the SSR.
		if (renderingState !== RenderingStates.ClientSide && pageContent) {
			if (window && languages) {
				window.__culture = languages.culture;
			}

			return;
		}

		// Send a history.push state preventFetch=true to prevent doing any request when the url is updated
		if (
			(history.location.state as any)?.preventFetch &&
			history.action === 'PUSH'
		) {
			return;
		}

		dispatch(fetchPage(apiUrl, siteRoute, querystring, hashstring));
		// eslint-disable-next-line
	}, [apiUrl, siteRoute, dispatch]);

	// Set renderingState to clientside after the first (hydration) render.
	useEffect(() => {
		dispatch(updateRenderingState(RenderingStates.ClientSide));
		// eslint-disable-next-line
	}, []);

	// If we have been waiting for the response more than 400ms we display the loader
	useEffect(() => {
		let loaderTimeout: any;

		if (loading) {
			loaderTimeout = setTimeout(() => {
				// Tell sighted users
				setLoaderVisible(true);
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = 'Sidan laddar';
				}
				setTimeout(() => {
					if (ariaLiveRef.current) {
						ariaLiveRef.current.innerHTML = '';
					}
				}, 500);
			}, 400);
		} else {
			setLoaderVisible(false);
			if (action === 'PUSH' && !hashstring) {
				window.scrollTo(0, 0);
			}

			if (hashstring) {
				setTimeout(() => {
					const targetElement = document.getElementById(
						hashstring.substring(1)
					);
					if (targetElement) {
						window.scrollTo({
							top: targetElement.offsetTop,
							behavior: 'smooth',
						});
					}
				}, 0);
			}
		}

		return () => {
			if (loaderTimeout) {
				clearTimeout(loaderTimeout);
			}
		};
		// eslint-disable-next-line
	}, [loading]);

	// Page is loaded
	useEffect(() => {
		// if (renderingState !== RenderingStates.ClientSide && pageContent) {
		// 	return;
		// }

		if (pageContent) {
			if (ariaLiveRef.current) {
				ariaLiveRef.current.innerHTML = 'Sidan har laddats';
			}
			setTimeout(() => {
				if (ariaLiveRef.current) {
					ariaLiveRef.current.innerHTML = '';
				}
			}, 500);

			if (emptyFocusDivRef.current) {
				let x = window.scrollX;
				let y = window.scrollY;
				if (document.hasFocus() && document.activeElement === document.body) {
					emptyFocusDivRef.current.focus();
				}
				window.scrollTo(x, y);
			}
			setTimeout(() => {
				if (pageRef.current) {
					let x = window.scrollX;
					let y = window.scrollY;
					if (
						document.hasFocus() &&
						document.activeElement === emptyFocusDivRef.current
					) {
						const firstH1 = pageRef.current.querySelector('h1');
						if (firstH1) {
							firstH1.setAttribute('tabindex', -1);
							firstH1.style.outline = 'none';
							firstH1.focus();
						} else {
							pageRef.current.focus();
						}
					}
					window.scrollTo(x, y);
				}
			}, 1000);

			if (window.dataLayer && window.dataLayer.push) {
				window.dataLayer.push({
					expandedBlock: undefined,
				});
				window.dataLayer.push({
					event: 'Pageview',
					pagePath: window.location.pathname || pageContent.url,
					pageTitle: metaData?.title,
					pageType: pageContent.modelType,
				});
			}

			if (
				!isFirstRender &&
				window.CookieInformation &&
				window.CookieInformation.loadConsent
			) {
				window.CookieInformation.loadConsent();
			}
		}
	}, [pageContent, metaData]);

	return (
		<>
			{loading && loaderVisible && (
				<Loader
					variant="default"
					className="fixed top-1/2 left-1/2 -mt-24 -ml-24 z-50"
				/>
			)}
			{error && error.indexOf('404') !== -1 && <ErrorPage404 error={error} />}
			{pageContent && !error && (
				<div ref={pageRef} tabIndex={-1} style={{ outline: 'none' }}>
					<Page {...pageContent} />
				</div>
			)}
			<div className="sr-only" aria-live="assertive" ref={ariaLiveRef}></div>
			<div
				tabIndex={-1}
				ref={emptyFocusDivRef}
				style={{ outline: 'none' }}
			></div>
		</>
	);
};
export { RouteHandler };
