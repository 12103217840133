/**
 * CalendarEventPage
 */

import React from 'react';
import {
	Grid,
	Cell,
	ArticleHeader,
	ArticleHeaderCategory,
	ArticleHeaderCategoryList,
	Toc,
	TocLink,
	ImageHeader,
	CalendarEventDetailList,
	CalendarEventDetailListItem,
} from 'ui-component-library/base';
import { CalendarEventPageModel } from 'types';
import BaseLayout from 'layouts/BaseLayout';
import Text from 'components/Text';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';
import EpiContentArea from 'components/EpiContentArea';
import Link from 'components/Link';
import { useIsDesktop } from 'hooks/useMediaQuery';

/** CalendarEventPage component. */
const CalendarEventPage: React.FC<CalendarEventPageModel> = ({
	heading,
	preamble,
	text,
	tableOfContents,
	articleHeader,
	imageHeader,
	calendarEventDetails,
	contentArea,
}) => {
	const isDesktop = useIsDesktop();

	return (
		<BaseLayout>
			{imageHeader && (
				<ImageHeader
					src={imageHeader.src}
					alt={imageHeader.altText}
					figCaption={imageHeader.text}
					className="-mt-10 md:-mt-8"
					theme="default"
				/>
			)}
			<Grid
				padding={false}
				margin={false}
				className={clsx(
					imageHeader ? 'pt-2 md:pt-8' : '-mt-4 md:mt-0 md:pt-2',
					'lg:pl-6 px-4'
				)}
			>
				<Cell span={12} desktop={7}>
					<Text as="h1" prop="heading">
						{heading}
					</Text>
					{articleHeader && (
						<ArticleHeader
							published={articleHeader.published}
							social={articleHeader.social}
							className="mb-8"
						>
							{articleHeader.categories && articleHeader.categories.length > 0 && (
								<ArticleHeaderCategoryList>
									{articleHeader.categories.map((category, index) => {
										return (
											<ArticleHeaderCategory
												key={index}
												url={category.url}
												text={category.text}
												LinkComponent={Link}
											/>
										);
									})}
								</ArticleHeaderCategoryList>
							)}
						</ArticleHeader>
					)}
					{calendarEventDetails && calendarEventDetails.length > 0 && (
						<div className="mb-8 lg:-mx-2">
							<CalendarEventDetailList>
								{calendarEventDetails.map((item, index) => {
									return (
										<CalendarEventDetailListItem
											key={index}
											heading={item.heading}
										>
											{item.type === 'link' && item.url ? (
												<Link
													to={item.url}
													className="text-teal hover:underline"
												>
													{item.text}
												</Link>
											) : (
												item.text
											)}
										</CalendarEventDetailListItem>
									);
								})}
							</CalendarEventDetailList>
						</div>
					)}
				</Cell>
			</Grid>
			<Grid
				margin={false}
				padding={false}
				className={clsx(tableOfContents && 'flex-row-reverse', 'lg:px-6 px-4')}
			>
				{isDesktop && tableOfContents && (
					<Cell span={4} className="ml-1/12 pl-8">
						<Toc
							heading={tableOfContents.heading}
							items={tableOfContents.chapters}
							sticky={tableOfContents.isSticky}
						>
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					</Cell>
				)}

				<Cell span={12} desktop={7}>
					<Text as="preamble" prop="preamble">
						{preamble}
					</Text>
					{!isDesktop && tableOfContents && (
						<Toc heading={tableOfContents.heading} items={[]} className="my-10">
							{tableOfContents.titles.map((item, index) => (
								<TocLink href={`#${item.targetId}`} key={index}>
									{item.text}
								</TocLink>
							))}
						</Toc>
					)}
					<EpiFragments prop="text" fragments={text?.fragments} />
				</Cell>
			</Grid>
			{contentArea && contentArea.length > 0 && (
				<EpiContentArea content={contentArea} />
			)}
		</BaseLayout>
	);
};

export default CalendarEventPage;
