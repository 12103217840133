/**
 * BaseLayout
 * TODO: Använd tailwind theme för att hitta rätt mediaquery värde
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	selectBreadcrumbs,
	selectCookieBar,
	selectSkipToMainContent,
	selectAlertBar,
	selectLogotype,
	selectContent,
	selectFooterQuickLinks,
	selectFooterContact,
	selectFooterCopyrightFooter,
} from 'store/modules/model';
import {
	Cookiebar,
	SkipToMainContent,
	Text,
	AlertBar,
	Breadcrumbs,
	BreadcrumbsItem,
	Grid,
	Cell,
	Icon,
} from 'ui-component-library/base';

import {
	BottomContent,
	ContentWrapper,
	PageFooter,
	PageFooterBottom,
	PageHeader,
} from 'ui-component-library/afori';
import Link from 'components/Link';
import Meta from 'components/Meta';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';
import { iconTypes } from 'ui-component-library/base/Icon';

interface Props {
	IntroBlock?: any;
}

/** BaseLayout that adds header and footer to the page. */
const BaseLayout: React.FC<Props> = ({ IntroBlock, children }) => {
	const breadcrumbs = useSelector(selectBreadcrumbs);
	const alertBar = useSelector(selectAlertBar);
	const cookieBar = useSelector(selectCookieBar);
	const skipToMainContent = useSelector(selectSkipToMainContent);
	const logotype = useSelector(selectLogotype);
	const content = useSelector(selectContent);
	const contact = useSelector(selectFooterContact);
	const quickLinksFooter = useSelector(selectFooterQuickLinks);
	const copyrightFooter = useSelector(selectFooterCopyrightFooter);
	const [showCookieBar, setShowCookieBar] = useState(false);
	useEffect(() => {
		setShowCookieBar(true);
	}, []);

	return (
		<div>
			<Meta />
			{alertBar &&
				alertBar.alerts.map((item, index) => {
					return (
						<AlertBar
							key={index}
							alertbarName={`${item.id}`}
							hideIfAlertbarSet={true}
							isCloseable={item.isCloseable}
							closeButtonLabel={item.closeButtonLabel}
						>
							{item.text.fragments.map((fragment, index) => (
								<div
									dangerouslySetInnerHTML={{ __html: fragment.raw }}
									key={index}
								/>
							))}
						</AlertBar>
					);
				})}
			{cookieBar && showCookieBar && (
				<Cookiebar
					cookieName="uhmBeta"
					hideIfCookieSet={true}
					open={true}
					buttonText={cookieBar.buttonText}
				>
					<div className="richtext richtext--altfont">
						<h1 className="small">{cookieBar.heading}</h1>

						<EpiFragments prop="text" fragments={cookieBar.text?.fragments} />
					</div>
				</Cookiebar>
			)}
			<PageHeader
				title={logotype?.alt || 'Startsida'}
				logoLink={logotype?.url || '/'}
				logoSrc={logotype?.logo}
				IntroBlockComponent={IntroBlock}
			>
				<SkipToMainContent href="#main-content">
					<Text as="span">
						{skipToMainContent?.text || 'Hoppa till huvudinnehållet'}
					</Text>
				</SkipToMainContent>
			</PageHeader>

			{breadcrumbs && breadcrumbs.length > 1 && (
				<Breadcrumbs aria-label="Brödsmulor">
					{breadcrumbs.map((item, index) => (
						<BreadcrumbsItem
							key={index}
							url={!item.isActive && item.url ? item.url : ''}
							text={item.text}
							LinkComponent={Link}
						/>
					))}
				</Breadcrumbs>
			)}
			<main
				className={clsx(
					'global__page-content min-h-70vh',
					(!content?.contentArea || content?.contentArea.length === 0) &&
						'mb-20',
					!IntroBlock && 'pt-10 md:pt-8'
				)}
				id="main-content"
			>
				{children}
			</main>
			<PageFooter className="global__page-footer">
				{(copyrightFooter ||
					contact?.social ||
					quickLinksFooter?.quickLinks) && (
					<PageFooterBottom>
						<Grid padding={false} className="px-4 lg:px-6">
							<Cell span={12}>
								<ContentWrapper>
									<div className="mb-2 lg:mb-0">
										{copyrightFooter && (
											<span className="footer__text text-white">
												{copyrightFooter}
											</span>
										)}
										{quickLinksFooter &&
											quickLinksFooter.quickLinks.map((link, index) => (
												<Link
													className="text-base footer__linklist footer__dl leading-6 text-white footer__link"
													href={link.href}
													key={index}
													target={link.target}
												>
													{link.text}
												</Link>
											))}
									</div>

									<BottomContent>
										{contact &&
											contact.social.map((item, index) => (
												<a
													href={item.value}
													aria-label={item.name}
													title={item.name}
													key={index}
												>
													<Icon
														icon={item.type as iconTypes}
														size={4}
														className="px-1 py-2 border-2 rounded-full text-white text-h2 bg-blackDark border-blackDark hover:bg-yellow hover:border-yellow hover:text-white"
													/>
												</a>
											))}
									</BottomContent>
								</ContentWrapper>
							</Cell>
						</Grid>
					</PageFooterBottom>
				)}
			</PageFooter>
		</div>
	);
};

export default BaseLayout;
